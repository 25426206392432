import Login from "../src/components/login/Login";
import Home from "./Home";
import Customer from "./Customer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dataset from "./Dataset";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/dataset/" element={<Dataset />} />
          <Route path="/customer" element={<Customer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
