import React, { useState } from 'react';
import $ from 'jquery'; // Import jQuery
import './login.css';

const Navbar = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = () => {
        setError('');
        // Validate input fields
        if (!username || !password) {
            setError('Please enter username and password');
            return;
        }

        // AJAX request using jQuery
        var browserProtocol = (("https:" === document.location.protocol) ? "https://" : "http://");
        $.ajax({
            url: browserProtocol + window.location.hostname + "/web-forms/process.php",
            // url: browserProtocol + "localhost/web-forms/process.php",
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
                username: $('#username').val(),
                password: $('#password').val(),
                actionVar: 'loginUser'
            }),
            success: function (data) {
                // Check login response
                if (data.success) {
                    // Login successful, call parent component's login function
                    window.location.href = '/dashboard';
                } else {
                    setError(data.message || 'Login failed');
                }
            },
            error: function (xhr, status, error) {
                setError('An error occurred. Please try again later.');
                console.error('Error:', error);
            }
        });
    };

    return (
        <>
            <div className="login-container" id="container">

                <div className="form-container sign-in-container">

                    <form action="#">
                        <div className="form-img">
                            <img src="https://growth.ooo/assets/media/logo/L166962784312.svg" alt="" />
                        </div>
                        <h1 class="h">Sign in</h1>
                        <span>{error}</span>
                        <input type="email" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Email" />
                        <input type="password" id='password' name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                        <button onClick={handleLogin}>Sign In</button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay-panel-logo overlay-right logo ">
                        <img src="https://growth.ooo/assets/media/logo/L166962784312.svg" alt="" />
                    </div>
                    <div className="overlay-panel-img overlay-right side-img">
                        <img src="https://growth.ooo/assets/media/growth-options.png" alt="" />
                    </div>


                </div>
            </div>

        </>
    )
}

export default Navbar
