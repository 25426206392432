import React from 'react'
import Navbar from './components/navbar/Navbar'
import Aside from './components/asidediv/Aside'
import Homepage from './components/home/Homepage'

const Home = () => {
    return (
        <div className="main_page">
            <Aside />
            <Navbar />
            <Homepage />
        </div>

    )
}

export default Home