import React from 'react'
import './addclient.css'
const AddClient = () => {
    return (
        <div className='form-container'>

            <div className="header-info d_flex gap-24">

                <span className='heading-header'>Add/Edit Customer</span>
                <span className='d_flex gap-8 header-path'>
                    <i className='fa fa-home header-icon'></i>
                    <span> - customers - </span>
                    <span>add/edit customer</span>
                </span>

            </div>

            <div className='form-body'>
                <form action="" className='client-form d_start flex_col gap-24 '>


                    <div className="form-header">
                        <h4>Add/Edit Customer</h4>
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Company Name</label>
                        <input type="text" name="clientName" id="clientName" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Contact Person</label>
                        <input type="text" name="clientName" id="clientName" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Email</label>
                        <input type="email" name="clientName" id="clientName" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Phone Number</label>
                        <input type="text" name="clientName" id="clientName" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Growth Score</label>
                        <input type="text" name="clientName" id="clientName" value="" />
                    </div>

                    <div className="text-box">
                        <label htmlFor="">Banner</label>
                        <input type="file" name="clientName" id="clientName" className="text-file" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Tier</label>
                        <select name="" id="" className=''>
                            <option value="">Go Business</option>
                            <option value="">Go VIP</option>
                            <option value="">Go Startup</option>
                        </select>
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Project Running Status</label>
                        <select name="" id="" className='' >
                            <option value="">Good</option>
                            <option value="">Bad</option>
                            <option value="">Great</option>
                        </select>
                    </div>
                    <div className="text-box">
                        <label htmlFor="">Seo Report Date</label>
                        <input type="date" name="clientName" className="text-file" id="clientName" value="" />
                    </div>
                    <div className="text-box">
                        <label htmlFor=""> Status</label>
                        <select name="" id="" className='' >
                            <option value="">Active</option>
                            <option value="">Inactive</option>
                        </select>
                    </div>

                    <div className="form-footer d_start gap-16 ">
                        <div className="submit-btn">
                            <a href="javascript:void(0);" className="submit-btn-a">
                                <button type='submit'>Save Changes</button>
                            </a>
                        </div>
                        <div className="cancel-btn">
                            <a href="#" className="cancel-btn-a">
                                <button type='submit'>Cancel</button>
                            </a>
                        </div>
                    </div>



                </form>
            </div>
            <div className="copyright">
                2024 © GoCommercially | Business Growth Platform
            </div>


        </div>
    )
}
export default AddClient