import React from 'react'
import Navbar from './components/navbar/Navbar'
import Aside from './components/asidediv/Aside'
import AddClient from './components/Client/AddClient'
const Customer = () => {
    return (
        <div className="main_page">
            <Aside />
            <Navbar />
            <AddClient />
        </div>

    )
}

export default Customer