import React from 'react'
import './activitycard.css'

const ActivityCard = () => {
    return (
        <div className='activity-container'>
            <div className="activity-card-box">
                <div className="activity-card">
                    <div className="card-header d_flex">
                        <h3>Objectives</h3>
                    </div>
                    <div className="card-body">

                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">Umbrella Branding</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>
                        <div className="card-item d_flex gap-16">
                            <div className="checkbox">
                                <input type="checkbox" className='checkbox-input' disabled />
                                <label></label>
                            </div>
                            <div className="title flex_col gap-8" >
                                <span className="title-span">India's 1st EV truck Positioning</span>
                                <span>Brand Objectives</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="activity-card activity-log">
                    <div className="card-header d_flex">
                        <h3>Activity Log</h3>
                    </div>
                    <div className="card-body">
                        <div className="log-items">
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 red-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task w24 07:13 PM. IP Address: 119.82.86.122 67 on 14-03-2024
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    UpdateIP Address: 119.82.86.122 67 on 14-03-2024
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 red-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 red-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task 7:13 PM. IP Address: 119.82.86.12 67 on 14-03-2024
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 red-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 red-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                            <div className="log-item d_start gap-16 blue-circle">
                                <div className="date-time d_start flex_col gap-4">
                                    <span className="date">23 Mar</span>
                                    <span className="time">07:23</span>
                                </div>
                                <div className="info">
                                    Updated Task with Task ID : 67 on 14-03-2024 07:13 PM. IP Address: 119.82.86.122
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="activity-card">
                    <div className="card-header d_flex">
                        <h3>Experiment Audit</h3>
                    </div>
                    <div className="card-body">
                        <div className="audit-items">
                            <div className="audit-item d_start gap-16 blue-circle">
                                <div className="info title flex_col gap-8">
                                    <span className="title-span">Marketing Campaign</span>
                                    <span>Revenue</span>
                                </div>
                            </div>
                            <div className="audit-item d_start gap-16 red-circle">
                                <div className="info title flex_col gap-8">
                                    <span className="title-span">Pan India</span>
                                    <span>Visibility</span>
                                </div>
                            </div>
                            <div className="audit-item d_start gap-16 blue-circle">
                                <div className="info title flex_col gap-8">
                                    <span className="title-span">CRO</span>
                                    <span>Conversion System</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default ActivityCard
