import React, { useState } from 'react'
import './navbar.css';
import Logo from '../../assets/img/logo/Logo.svg';

import '../../../src/assets/css/main.css';

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const [subtoggle, setSubtoggle] = useState('');
    const [subtoggleState, setSubtoggleState] = useState(false);
    const toggleBar = () => {
        setToggle(!toggle);
    }

    const subButton = (idx) => {
        setSubtoggle(idx);
        setSubtoggleState(!subtoggleState);
    }
    return (
        <>
            <header class="header-section">
                <div class="container">
                    <div class="header-wrapper">
                        <div class="logo-menu">
                            <a href="index.html" class="logo">
                                <img src={Logo} alt="img" />
                            </a>
                        </div>
                        <div class="logo-menu-content">
                            <a href="index.html" class="logo">
                                Go Growth
                            </a>
                        </div>
                        <div class={`header-bar d-lg-none ${toggle ? 'active' : ''}`} onClick={() => toggleBar()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div class={`main-menu ${toggle ? 'active' : ''}`}>
                            <ul className='main_ul'>
                                <li class="active">
                                    <a href="javascript:void(0);" onClick={() => subButton('objective')}>Objectives <i class="fas fa-chevron-down"></i></a>
                                    <ul class={`sub-menu home__subs ${(subtoggle === 'objective' && subtoggleState) ? 'active' : ''}`}>
                                        <li class="subtwohober">
                                            <a href="index.html">
                                                <span><i class="fa-solid fa-house"></i></span>
                                                <span>Home One</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="home.html">
                                                <span><i class="fa-solid fa-house"></i></span>
                                                <span>Home Two</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="sharp-home.html">
                                                <span><i class="fa-solid fa-house"></i></span>
                                                <span>Home Three</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="about.html">Initiatives</a>
                                </li>
                                <li>
                                    <a href="pricing.html">Experiments</a>
                                </li>
                                <li>
                                    <a href="resserler.html">Revenue</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" onClick={() => subButton('kpi')}>KPI <i class="fas fa-chevron-down"></i></a>
                                    <ul class={`sub-menu ${(subtoggle === 'kpi' && subtoggleState) ? 'active' : ''}`}>
                                        <li class="subtwohober">
                                            <a href="resserler.html">
                                                <span class="icon"><i class="fa-brands fa-audible"></i></span>
                                                <span>Reseller Hosting</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="sharehost.html">
                                                <span class="icon"><i class="fa-solid fa-server"></i></span>
                                                <span>Shared Hosting</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="cloudhost.html">
                                                <span class="icon"><i class="fa-solid fa-cloud"></i></span>
                                                <span>Vps Hosting</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="services.html">
                                                <span class="icon"><i class="fa-solid fa-cloud"></i></span>
                                                <span>Services</span>
                                            </a>
                                        </li>
                                        <li class="subtwohober">
                                            <a href="blog.html">
                                                <span class="icon"><i class="fa-solid fa-sitemap"></i></span>
                                                <span>Blog</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="btn--items">
                                    <a href="contact.html" class="cmn--btn">
                                        <span>Contact us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <a href="contact.html" class="cmn--btn">
                            <span>Contact us</span>
                        </a>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar
