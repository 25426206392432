import React from 'react'
import './trafficcard.css'
import Graph from '../../assets/img/logo/graph.png';
import Graph2 from '../../assets/img/logo/graph2.png';

const TrafficCard = () => {
    return (
        <div className='traffic-container'>
            <div className="traffic-card-box">
                <div className="traffic-card">

                    <div className="card-body">
                        <div className="card-item d_flex space_between">
                            <div className="details d_start flex_col gap-8">
                                <span className="title">Traffic</span>
                                <span className="title-value">Average Monthly Traffic</span>
                            </div>
                            <div className="value" >
                                <span className="title-span">475.00</span>
                            </div>
                        </div>
                        <div className="card-item d_flex space_between">
                            <div className="details d_start flex_col gap-8">
                                <span className="title">Bounce Rate %</span>
                                <span className="title-value">Average Monthly Bounce Rate</span>
                            </div>
                            <div className="value" >
                                <span className="title-span">15.00</span>
                            </div>
                        </div>
                        <div className="card-item d_flex space_between">
                            <div className="details d_start flex_col gap-8">
                                <span className="title">Conversions</span>
                                <span className="title-value">Average Monthly Conversions</span>
                            </div>
                            <div className="value" >
                                <span className="title-span">175.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="traffic-card">

                    <div className="card-body leads">
                        <div className="daily-leads flex_col gap-8">
                            <span className='title'>Daily Leads</span>
                            <span className='title-value'>Check out each column for more details</span>
                        </div>
                        <div className="daily-leads-graph">
                            <div className="graph-img">
                                <img src={Graph} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="traffic-card">

                    <div className="card-body leads">
                        <div className="card-item growth-rate d_flex space_between">
                            <div className="details d_start flex_col gap-8">
                                <span className="title-value">% as per groups</span>
                            </div>
                            <div className="value" >
                                <select name="growth-rate" id="growth-rate" className='growth-rate-select'>
                                    <option value="objectives">Objectives</option>
                                    <option value="objectives">Initiatives</option>
                                    <option value="objectives">Experiments</option>
                                    <option value="objectives">Revenue</option>
                                </select>
                            </div>
                        </div>
                        <div className="daily-leads-graph">
                            <div className="graph-img">
                                <img src={Graph2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default TrafficCard
