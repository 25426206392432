import React from 'react'
import Navbar from './components/navbar/Navbar'
import Aside from './components/asidediv/Aside'
import Datainfotable from './components/datatable/Datainfotable'

const Dataset = () => {
    return (
        <div className='data_table'>
            <Navbar />
            <Aside />
            <Datainfotable />
        </div>
    )
}

export default Dataset
