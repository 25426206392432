import React from 'react'
import './intro.css'
import Clock from '../../assets/img/home/clock.png';
import $ from 'jquery';

const Intro = () => {


    const handleClick = () => {
        // Make AJAX request
        $.ajax({
            url: 'http://localhost/react/index.php',
            method: 'GET',
            success: (data) => {
                // Handle the data from the response
                $('#span').text(data);
                console.log(data);
            },
            error: (xhr, status, error) => {
                // Handle errors
                console.error('There was a problem with the AJAX request:', error);
            }
        });
    };
    return (

        <div className="main-container">
            <div className='intro-container d_flex'>
                <div className="left-container flex_col">
                    <div className="upper-container d_start gap-32">
                        <div className="image-container">
                            <img src="https://growth.ooo/assets/media/clients/fabulous-logo.svg" alt="" />
                        </div>
                        <div className="content flex_col gap-40">
                            <div className="title d_flex gap-8">
                                <span>Technocrats <span id="span"></span></span>
                                <span><i class="fa-solid fa-circle-check"></i></span>
                                <span>Upgrade the Growth Score</span>
                            </div>
                            <div className="cards d_flex gap-24">
                                <div className="card">
                                    <span><i class="fa-solid fa-arrow-up" style={{ color: '#009ef7' }}></i></span>
                                    <span>Initiator</span>
                                </div>
                                <div className="card ">
                                    <span><i class="fa-solid fa-arrow-down" style={{ color: '#f1416c' }}></i></span>
                                    <span>Commited</span>
                                </div>
                                <div className="card">
                                    <span><i class="fa-solid fa-arrow-up" style={{ color: '#f1416c' }}></i></span>
                                    <span>Accelerated</span>
                                </div>
                                <div className="card">
                                    <span><i class="fa-solid fa-arrow-up" style={{ color: '#f1416c' }}></i></span>
                                    <span>Elevated</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-container">
                        <ul className="branding-links d_flex gap-40">
                            <li><a href="javascript:void(0);" className='active'>Overview</a></li>
                            <li><a href="javascript:void(0);">Branding</a></li>
                            <li><a href="javascript:void(0);">Marketing</a></li>
                            <li><a href="javascript:void(0);">Technology</a></li>
                            <li><a href="javascript:void(0);">Growth</a></li>
                        </ul>
                    </div>
                </div>
                <div className="right-container flex_col gap-24">
                    <div className="heading d_start flex_col gap-8">
                        <h2>Growth Score</h2>
                        <span>Your business growth percentage</span>
                    </div>
                    <div className="clock d_flex flex_col gap-8" >
                        <img src={Clock} alt="" />
                        <span>1000</span>

                    </div>
                    <div className="report d_flex">
                        <a href="javascript:void(0);" onClick={handleClick}>View Report</a>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default Intro
