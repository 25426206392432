import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './home.css';
import Intro from '../intro/Intro';
import ActivityCard from '../activitycards/ActivityCard';
import TrafficCard from '../trafficcards/TrafficCard';

const Homepage = () => {

    useEffect(() => {
        // AJAX request using jQuery
        var browserProtocol = (("https:" === document.location.protocol) ? "https://" : "http://");
        $.ajax({
            url: browserProtocol + window.location.hostname + "/web-forms/checksession.php",
            method: 'GET',
            dataType: 'json',
            success: (data) => {
                if (!data.loggedIn) {
                    window.location.href = "/"
                }
            },
            error: (xhr, status, error) => {
                console.error('Error:', error);
            }
        });
    }, []);
    return (
        <>
            <div class="m-subheader-search">
                <h2 class="m-subheader-search__title">Welcome Technocrats</h2>
            </div>
            <Intro />
            <TrafficCard />
            <ActivityCard />
        </>
    )
}

export default Homepage
