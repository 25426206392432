import React from 'react'
import './aside.css';

const Aside = () => {
    return (
        <>
            <div id="m_aside_left" class="m-grid__item	m-aside-left  m-aside-left--skin-light ">
            </div>
        </>
    )
}

export default Aside
