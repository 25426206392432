import React, { useState } from 'react'
import './data.css'


const Datainfotable = () => {

    const [tabBtn, setTabBtn] = useState('brand');
    const changeTab = (tab) => {
        setTabBtn(tab);
    }
    return (
        <div className='data_info'>
            <div className="tab_button">
                <button className={tabBtn === 'brand' ? 'active' : ''} onClick={() => changeTab('brand')}>Brand Objective</button>
                <button className={tabBtn === 'market' ? 'active' : ''} onClick={() => changeTab('market')}>Market Objective</button>
                <button className={tabBtn === 'leads' ? 'active' : ''} onClick={() => changeTab('leads')}>Lead/Sales Objective</button>
            </div>
            <div className={tabBtn === 'brand' ? 'tab_content active' : 'tab_content'}>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={tabBtn === 'market' ? 'tab_content active' : 'tab_content'}>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={tabBtn === 'leads' ? 'tab_content active' : 'tab_content'}>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="single_data d_flex space_between">
                    <div className="tab_title d_flex space_between">
                        <div className='d_start gap-16'>
                            <div className='title_box flex_col gap-4'>
                                <h3>Management ORM</h3>
                                <span>Elements (0)</span>
                            </div>
                            <div className='data_check'>
                                <input type="checkbox" id="title9" />
                                <label htmlFor="title9"></label>
                            </div>
                        </div>
                        <div className="tab_delete">
                            <button><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div className="tab_range d_flex gap-40">
                        <div className='range_input'>
                            <input type="range" name="" id="" />
                        </div>
                        <div className="range_progress">
                            <span>68%</span>
                        </div>
                    </div>
                    <div className="tab_status">
                        <ul className='d_flex gap-8'>
                            <li><i class="fa-solid fa-square-plus"></i></li>
                            <li><i class="fa-solid fa-message"></i></li>
                            <li><i class="fa-solid fa-up-down-left-right"></i></li>
                            <li><i class="fa-solid fa-pen"></i></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Datainfotable
